<template>
  <div id="RecommendedBrand">
    <breadcrumb color="#fff" :name="$route.query.brandName" />
    <div class="brand">
      <el-carousel
        :interval="10000"
        arrow="always"
        height="350px"
        class="brand-img"
      >
        <!-- <el-carousel-item v-for="(item, index) in BrandImg" :key="index">
          <img v-lazy="item.url" alt="" :key="item.url" />
        </el-carousel-item> -->

        <img v-lazy="BrandImg" alt="" />
        <!-- <img src="@/assets/images/brand-t.png" /> -->
      </el-carousel>
      <div class="brand-introduce">
        <img
          v-lazy="DetailsOfTheBrand.logo"
          alt=""
          :key="DetailsOfTheBrand.logo"
        />
        <div class="introduce">
          <div class="left">
            <dl>
              <dt>品牌中文名</dt>
              ：
              <dd :title="DetailsOfTheBrand.brandName">
                {{ DetailsOfTheBrand.brandName }}
              </dd>
            </dl>
            <dl>
              <dt>品牌外文名</dt>
              ：
              <dd :title="DetailsOfTheBrand.brandEnName">
                {{ DetailsOfTheBrand.brandEnName }}
              </dd>
            </dl>
            <dl>
              <dt>创始时间</dt>
              ：
              <dd :title="DetailsOfTheBrand.initialTime | time">
                {{ DetailsOfTheBrand.initialTime | time }}
              </dd>
            </dl>
          </div>
          <div class="right">
            <dl>
              <dt>品牌原产地</dt>
              ：
              <dd :title="DetailsOfTheBrand.sourceArea">
                {{ DetailsOfTheBrand.sourceArea }}
              </dd>
            </dl>
            <dl>
              <dt>产品特点</dt>
              ：
              <dd :title="DetailsOfTheBrand.brandTrait">
                {{ DetailsOfTheBrand.brandTrait }}
              </dd>
            </dl>
            <dl>
              <dt>主要产业</dt>
              ：
              <dd :title="DetailsOfTheBrand.majorIndustry">
                {{ DetailsOfTheBrand.majorIndustry }}
              </dd>
            </dl>
          </div>
          <div class="left" style="margin-top: -10px">
            <dl>
              <dt>品牌官网</dt>
              ：
              <dd style="width: auto">
                <a
                  @click="open(DetailsOfTheBrand.officialUrl)"
                  :title="DetailsOfTheBrand.officialUrl"
                  >{{ DetailsOfTheBrand.officialUrl }}</a
                >
              </dd>
            </dl>
          </div>
        </div>
        <div class="dotted-line"></div>
        <div class="introduce-content">
          <p>{{ DetailsOfTheBrand.introduction }}</p>
        </div>
      </div>
    </div>
    <h2 class="title">
      <img src="@/assets/images/slash.png" alt="" />品牌商品<img
        src="@/assets/images/slash.png"
        alt=""
      />
    </h2>
    <goods-list />
    <!-- <recommended :goods="ItemCode" :flag="1" /> -->
    <recommended :goods="RecommendedGoods" />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import Recommended from "@/components/Recommended";
import Breadcrumb from "@/components/Breadcrumb";
import { formatDay } from "@/utils/validate";

export default {
  name: "recommended-brand",
  components: { Recommended, GoodsList, Breadcrumb },
  data() {
    return {
      DetailsOfTheBrand: "", //品牌详情
      BrandImg: "",
      OrdinaryGoods: "", //推荐商品信息
      ItemCode: "", //推荐商品单品编码
      RecommendedGoods: "", //推荐商品
      URL: "",
    };
  },
  // beforeRouteEnter: (to, from, next) => {
  //   console.log("to:", to);
  //   console.log("from:", from);
  //   console.log("next:", next);
  //   next();
  // },
  filters: {
    time(val) {
      if (val === null) {
        return "-";
      } else {
        return formatDay(val);
      }
    },
  },
  created() {
    this.getBrandDetails();
    this.getBrandRecommendedGoods();
  },
  mounted() {},
  methods: {
    //品牌详情
    async getBrandDetails() {
      const data = await this.$API.brand.BrandDetails({
        object: {
          id: this.$route.params.id,
        },
      });
      this.DetailsOfTheBrand = data.data.data;
      this.BrandImg = data.data.data.picPosition;
    },
    //品牌推荐商品

    async getBrandRecommendedGoods() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: this.$route.params.brandId,
          ceCategoryId: null,
          location: "8",
          order: "",
          sortColumn: "",
        },
        pageNum: 1,
        pageSize: 10,
      });
      this.RecommendedGoods = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
    },
    open(href) {
      // console.log("http://" + href);
      var tempwindow = window.open("_blank");
      tempwindow.location = "http://" + href;
    },
  },
};
</script>

<style >
#breadcrumb {
  background: #fff;
}
#Brand {
  background: #fff !important;
}
</style>
<style lang="scss" scoped>
.recommended {
  border-bottom: none;
  margin-bottom: 20px;
  ::v-deep .content {
    border: 1px solid rgb(238, 238, 238);
    border-top: none;
  }
}
#RecommendedBrand {
  background: #fff;
  border-top: 1px solid #00aeeb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .brand {
    width: 1200px;
    height: 350px;
    display: flex;
    .brand-img,
    .brand-introduce {
      width: 600px;
      img {
        width: 600px;
        height: 350px;
      }
    }
    .brand-img {
      overflow: hidden;
      ::v-deep.el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #000000;
        opacity: 0.4;
      }
    }
    .brand-introduce {
      border: 1px solid #e8e8e8;
      padding: 10px 16px;
      overflow-y: auto;
      img {
        margin: 8px 0;
        width: 100px;
        height: 56px;
      }
      .introduce {
        display: flex;
            flex-wrap: wrap;
        .left,
        .right {
          flex: 1;
          dl {
            display: flex;
            font-size: 14px;
            line-height: 19px;
            color: #666666;
            margin: 10px 0;

            dt {
              width: 70px;
              text-align-last: justify;
            }
            dd {
              width: 186px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #333333;
              a {
                color: #438afe;
                cursor: pointer;
              }
            }
          }
        }
      }
      .dotted-line {
        width: 100%;
        border: 1px dashed #ddd;
      }
      .introduce-content {
        p {
          margin: 10px 0;
          font-size: 14px;
          line-height: 26px;
          color: #666666;
        }
      }
    }
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    color: #333333;
    text-align: center;
    margin: 40px 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 12px;
    }
  }
}
</style>