<template>
  <div class="brand-goods">
    <breadcrumb color="#fff" />

    <goods-list />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import GoodsList from "@/components/GoodsList";

export default {
  name: "brand-goods",
  components: { GoodsList, Breadcrumb },
  data() {
    return {};
  },
  created() {
    // console.log(this.$route);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.brand-goods {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px #00AEEB;
}
</style>