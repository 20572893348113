<template>
  <div class="recommended">
    <div class="title" ref="title">
      <div class="left">
        <img src="@/assets/images/recommended.png" alt="" v-if="!isStore" />
        <img src="@/assets/images/recommended-b.png" alt="" v-if="isStore" />
        <h2>推荐商品</h2>
      </div>
      <div class="right">
        <router-link to="/AllGoods" v-if="!isStore"
          >全部商品<svg-icon icon-class="ic-arrow-d"
        /></router-link>
      </div>
    </div>
    <div
      class="content"
      :style="goods.length === 0 ? 'display:block' : 'display:flex'"
    >
      <goods v-for="(item, index) in goodsInfo" :key="index" :information="item" />
      <el-empty description="暂无商品" v-if="goodsInfo.length === 0"></el-empty>
    </div>
  </div>
</template>

<script>
import Goods from "@/components/Goods";
export default {
  name: "recommended",
  props: ["goods"],
  components: {
    Goods,
  },
  data() {
    return {
      isStore: false,
      goodsInfo:''
    };
  },
  watch: {

    goods: {
      handler: function (val, oldVal) {
        this.goodsInfo = val
      },
      immediate:true,
      deep: true,
    },
  },
  created() {
    if (
      this.$route.name === "home-page" ||
      this.$route.name === "recommended-brand"
    ) {
      this.isStore = true;
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.isStore) {
        this.$refs.title.style.height = "54px";
        this.$refs.title.style.border = "1px solid #EEEEEE";
        this.$refs.title.style.background = "#F2F4F9";
      }
    });
  },
  methods: {
    // goAllGoods() {
    //   this.$router.push({
    //     name: `all-goods`,
    //     query: {
    //       flag: this.flag,
    //       storeCode: this.$route.params.id,
    //       enterpriseId: this.$route.query.enterpriseId,
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.recommended {
  width: 1200px;
  background: #fff;
  .title {
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    .left {
      display: flex;
      align-items: center;
      img {
        // width: 30px;
        // height: 30px;
        margin-right: 10px;
      }
      h2 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      p {
        height: 20px;
        font-size: 14px;
        color: #666;
      }
    }
    .right {
      a {
        font-size: 14px;
        color: #666666;
        display: flex;
        align-items: center;
        cursor: pointer;
        .svg-icon {
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }
  }
  .content {
    display: flex;

    flex-wrap: wrap;
    .goods-box {
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
    }
    .goods-box:nth-child(5n) {
      border-right: none;
      width: 238px;
    }
  }
}
</style>