<template>
  <div id="breadcrumb" :style="{ 'background-color': `${color}` }">
    <div class="breadcrumb-con">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
      </el-breadcrumb>
      <svg-icon icon-class="ic-close" />
    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  props: ["color"],

  data() {
    return {
      breadList: [],
    };
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#breadcrumb {
  background: #f5f5f5;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  .breadcrumb-con {
    width: 1200px;
    height: 55px;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: #999;
    }
    ::v-deep.el-breadcrumb {
      span {
        color: #999;
      }
      .el-breadcrumb__inner {
        font-weight: normal;
      }
      .el-breadcrumb__inner:hover {
        color: #00aeeb;
      }
    }
    ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
      color: #333;
    }
    .svg-icon {
      margin-left: 4px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
    .svg-icon:hover {
      color: #000;
    }
  }
}
</style>